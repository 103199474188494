import { Typography } from '../models/theme';

export const typography: Typography = {
    heading1: {
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '49px',
    },
    heading2: {
        fontSize: '36px',
        fontWeight: 700,
        lineHeight: '44px',
    },
    heading3: {
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: '39px',
    },
    heading4: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '29px',
    },
    heading5: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '17px',
    },
    heading6: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '20px',
    },
    subtitle1: {
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '34px',
    },
    subtitle2: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '29px',
    },
    subtitle3: {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    subtitle4: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '32px',
    },
    body1: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
    },
    body2: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '17px',
    },
    button: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '19.5px',
    },
    label1: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '17px',
    },
    label2: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '22px',
    },
    label3: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '19px',
    },
    caption: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '15px',
    },
    overline: {
        fontSize: '10px',
        fontWeight: 500,
        lineHeight: '12px',
    },
    dropdown: {
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '1',
    },
    tabText: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '25px',
    },
    chip: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18px',
    },
    subtitle5: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '26px',
    },
    subtitle6: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '21px',
    },
    label4: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
    },
};
