import { useContext } from 'react';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import {
    InteractionType,
    PublicClientApplication,
    BrowserCacheLocation,
} from '@azure/msal-browser';
import { ComponentProps } from '../../common/types';
import { configureHttp } from '../http/configureHttp';
import { ConfigContext } from '../../common/context/Config';

export default function MsalAuthWrapper({ children }: ComponentProps) {
    const config = useContext(ConfigContext);

    const client = new PublicClientApplication({
        auth: {
            clientId: config.msal.clientId,
            authority: config.msal.authority,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
    });

    configureHttp(client, config);

    return (
        <MsalProvider instance={client}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
            >
                <>{children}</>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}
