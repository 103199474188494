export const colors = {
    downriver: '#08213C',
    shakespeare: '#4295CD',
    shakespeareLight: '#5BA4D5',
    java: '#1ADBC0',
    botticelli: '#DBE2EF',
    linkWater: '#EDF5FA',
    madison: '#0F3A61',
    bayOfMany: '#1F4A73',
    lynch: '#667D97',
    heather: '#BAC6D3',
    athensGray: '#E4E8ED',
    astral: '#3672A5',
    pictonBlue: '#64A9D7',
    aliceBlue: '#EEF8FF',
    blueBayoux: '#4F5E74',
    seagull: '#8BD0EE',
    tropicalBlue: '#B7E3F5',
    hummingBird: '#E2F4FB',
    tropicalRainForest: '#007A54',
    greenHaze: '#009C74',
    persianGreen: '#00B994',
    caribbeanGreen: '#00CEA5',
    caribbeanGreenSub: '#00BAA0',
    polar: '#D8F6F3',
    riptide: '#94E9DA',
    whiteIce: '#D5F7F1',
    congressBlue: '#00408D',
    matisse: '#1E5D9D',
    shipeCove: '#6988B8',
    pigeonPost: '#A5B7D6',
    catskillWhite: '#E4E9F3',
    funBlue: '#15579D',
    dodgerBlue: '#00B0FF',
    turquoiseBlue: '#5AD1ED',
    heliotrope: '#B388FF',
    azureRadiance: '#0091EA',
    frenchPass: '#B3E5FC',
    cerulean: '#00B8D4',
    blizzardBlue: '#B2EBF2',
    electricViolet: '#7C4DFF',
    prelude: '#D1C4E9',
    javaLight: '#1DE9B6',
    aquaIsland: '#B2DFDB',
    pastelPink: '#FFCDD2',
    mineShaft: '#222222',
    tundora: '#444444',
    doveGray: '#666666',
    gray: '#888888',
    dustyGray: '#999999',
    bombay: '#ADB0B1',
    athensGrayLight: '#F8F9FA',
    alabaster: '#F9F9F9',
    hintOfRed: '#F9F7F7',
    vibrantGreen: '#14DFAE',
    vibrantPurple: '#4339F2',
    blue: '#2F80ED',
    darkBlue: '#1470EA',
    neonLight: '#2EEFBC',
    neonMedium: '#21CDB0',
    neonDark: '#17ACA1',
    concrete: '#F2F2F2',
    portage: '#9D96F5',
    algaeGreen: '#9FE6B8',
    pictonBlueLight: '#31C5E9',
    mustard: '#FFDB5C',
    vividTangerine: '#FF8A80',
    vividTangerineLight: '#FE9F7F',
    monaLisa: '#FF9D95',
    tuftBush: '#FFD8CA',
    sunsetOrange: '#FF5252',
    wildWatermelon: '#FF5174',
    brinkPink: '#FF718D',
    red: '#FF3A29',
    remy: '#FEE6EC',
    deepBlush: '#E062AE',
    seaGreen: '#32A355',
    suriousBlue: '#37A2DA',
    anakiwa: '#96BFFF',
    white: '#FFFFFF',
    black: '#000000',
    gallery: '#EDEDED',
    mercury: '#E5E5E5',
    solitude: '#F1F3F9',
    chineseSilver: '#cccccc',
    aliceBlueSub: '#E3F1FF',
    tickleMePink: '#FB7293',
    mediumAquamarine: '#5EC6A8',
    clearDay: '#E1F5F0',
    aquamarine: '#009E80',
    lightShadeGray: '#FAFAFA',
    aliceBlueSub1: '#EDF7FE',
    darkGray: '#AFAFAF',
    aliceBlueSub2: '#F6F8FB',
    roseBud: '#FEB299',
    dodgerBlueSub: '#3C8DE5',
    mountainMeadow: '#12A67C',
    royalBlue: '#3881E1',
    trueV: '#8660D1',
    aquaSpring: '#F8FBFD',
    caribbeanGreenSub1: '#00BFA5',
    bitter: '#888866',
    black1: 'rgba(0,0,0,0.1)',
    gray1: 'rgba(102, 102, 102, 0.95)',
    snow: '#FFF4F4',
    brinkPinkSub: '#FF7285',
    mauvelous: '#FC8EA9',
    aliceBlueSub3: '#F0F7FB',
    lavenderBlush: '#FFFBFC',
    aliceBlueSub4: '#F8F9FB',
    cello: '#394D63',
    shipCove: '#8493AD',
    hawkesBlue: '#d0dae8',
    solitude1: '#F4F6FA',
};
