import { AppConfig } from '../../common/models';

export const config: AppConfig = {
    apiUrl: 'https://dev-control-plane.cxicodes.com/api',
    msal: {
        clientId: 'd987e012-d8c9-4a30-bb49-7aa5a9e548c3',
        authority: 'https://login.microsoftonline.com/CXintelligence.onmicrosoft.com',
        apiScopes: {
            readWrite: 'api://35f4f4ea-086a-4927-b210-dae4004d4ec9/admin_portal_readwrite',
        },
    },
};
