import React, { Suspense } from 'react';
import { PageLoader } from '../components/page-loader';
import { BootstrapContainer } from './bootstrap/BootstrapContainer';
import MsalAuthWrapper from '../app/auth/MsalAuthWrapper';

const Root = React.lazy(() => import('./root/Root'));

function App() {
    return (
        <BootstrapContainer>
            <MsalAuthWrapper>
                <Suspense fallback={<PageLoader />}>
                    <Root />
                </Suspense>
            </MsalAuthWrapper>
        </BootstrapContainer>
    );
}

export default App;
