import { useEffect, useState } from 'react';
import { ConfigContext } from '../../common/context/Config';
import { AppConfig } from '../../common/models';
import { ComponentProps } from '../../common/types';
import { PageLoader } from '../../components/page-loader';
import { getAppConfig } from '../config/getAppConfig';

export function BootstrapContainer({ children }: ComponentProps) {
    let [appConfig, setAppConfig] = useState<AppConfig | null>(null);

    useEffect(() => {
        getAppConfig()
            .then((cfg) => {
                setAppConfig(cfg);
            })
            .catch(console.error);
    }, []);

    return appConfig ? (
        <ConfigContext.Provider value={appConfig}>
            {children}
        </ConfigContext.Provider>
    ) : (
        <PageLoader />
    );
}
