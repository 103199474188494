import axios from 'axios';
import {
    AccountInfo,
    IPublicClientApplication,
    RedirectRequest,
    SilentRequest,
} from '@azure/msal-browser';
import { AppConfig } from '../../common/models';

export function configureHttp(
    msalClient: IPublicClientApplication,
    appConfig: AppConfig
) {
    const { msal, apiUrl } = appConfig;
    axios.defaults.baseURL = apiUrl;

    axios.interceptors.request.use(async function (config) {
        const account = msalClient.getAllAccounts()[0] as AccountInfo;

        const tokenSilentRequest: SilentRequest = {
            authority: msal.authority,
            account,
            scopes: [msal.apiScopes.readWrite],
        };
        const tokenRedirectRequest: RedirectRequest = {
            ...tokenSilentRequest,
            redirectUri: window.location.origin,
        };

        try {
            const { accessToken } = await msalClient.acquireTokenSilent(
                tokenSilentRequest
            );

            if (accessToken) {
                config.headers = {
                    Authorization: `Bearer ${accessToken}`,
                    ...config.headers,
                };
                return config;
            } else {
                msalClient.acquireTokenRedirect(tokenRedirectRequest);
            }
        } catch {
            msalClient.acquireTokenRedirect(tokenRedirectRequest);
        }
    });
}
